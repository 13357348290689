import React, { memo, useEffect } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import dayAPI from '~/utils/dayAPI';
import { useSymbolHistoryResource } from '~/modules/screener/containers/useSymbolHistoryResource';
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC';
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore';
import { ResponsiveContainer, ReferenceLine, YAxis, XAxis, CartesianGrid, Area, Bar, Tooltip, ComposedChart, } from 'recharts-new';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { fontWeight600 } from '~/css/font';
import { useThemeOfParent } from '~/components/theme/useThemeOfParent';
import { isEmpty } from 'lodash';
import { NumericRange } from '../SDK/domain/value-objects';
import { Exchange } from '../SDK/domain/enumerations/exchange';
const IntradayTrendChart = memo(function IntradayTrendChart(props) {
    const { instrument } = props;
    const timeTicksCount = props.timeTicksCount || 8;
    const parentTheme = useThemeOfParent();
    const splitColorId = ('splitColor' + instrument.symbol.value).toString();
    const strokeColorId = ('strokeColor' + instrument.symbol.value).toString();
    const fillId = 'url(#' + splitColorId + ')';
    const strokeId = 'url(#' + strokeColorId + ')';
    const strokeColor = parentTheme.isDark ? '#333333' : '#efefef';
    const fillColor = parentTheme.isDark
        ? props.useFaiTheme
            ? parentTheme.isDark
                ? '#111824'
                : '#202D42'
            : '#222222'
        : '#ffffff';
    const chartMargin = {
        top: 8,
        right: 8,
        left: props.priceTicksMargin ?? 10,
        bottom: 0,
    };
    // Data fetching
    useEffect(() => {
        useSignalrStore.getState().subscribeAdd([instrument.symbol.value], 'ohlc');
        return () => {
            useSignalrStore.getState().subscribeRemove([instrument.symbol.value], 'ohlc');
        };
    }, [instrument.symbol.value]);
    const quote = useSignalrStoreValueOHLC(state => state.value[instrument.symbol.value]);
    const { data: rawData } = useSymbolHistoryResource({
        symbol: instrument.symbol.value,
        resolution: '1',
        intraday: true,
    });
    // Determine session and get time range
    const lastBarTime = isEmpty(rawData) ? dayAPI() : dayAPI(rawData?.at(-1)?.time);
    let range;
    let timeRange;
    let timeTicks;
    if (instrument.exchange.equals(Exchange.TFE)) {
        const sessions = instrument.sessions.current(lastBarTime) || instrument.sessions.previous(lastBarTime);
        range = sessions.toRange(lastBarTime);
        timeRange = range.toTimestampTuple(time => time.unix(), 2);
        timeTicks = sessions.splitAsTimestamps(lastBarTime, time => time.unix(), timeTicksCount);
    }
    else {
        const sessions = instrument.sessions.intraday(lastBarTime);
        range = sessions.toRange();
        timeRange = range.toTimestampTuple(time => time.unix(), 2);
        timeTicks = sessions.splitAsTimestamps(time => time.unix(), timeTicksCount);
    }
    // Map to chart data
    const prevClose = quote?.prevRef ?? 0;
    const chartData = rawData
        ?.filter(bar => {
        const time = dayAPI(bar.time);
        return time.isSameOrAfter(range.start) && time.isSameOrBefore(range.end);
    })
        .map(bar => {
        return {
            unixtime: dayAPI(bar.time).unix(),
            datetime: dayAPI(bar.time).format('HH:mm'),
            originalClose: bar.close,
            close: bar.close - prevClose,
            volume: bar.volume,
        };
    }) ?? [];
    if (isEmpty(chartData))
        return (<ResponsiveContainer width='100%' height='100%'>
        <ComposedChart data={[0]} margin={chartMargin}>
          <YAxis dataKey='close' hide={props.hidePriceLabel} yAxisId={1} tick={{
                fontSize: props.priceTicksSize ?? 14,
                fill: parentTheme.isDark ? '#CCCCCC' : '#666666',
            }} tickMargin={0} tickFormatter={value => (value + prevClose).toFixed(precision)}/>
          <YAxis dataKey='volume' hide={true} yAxisId={2}/>
          <XAxis dataKey='unixtime' type='number' height={props.ticksHeight} hide={props.hideTimeLabel ?? false} domain={timeRange} ticks={timeTicks} interval={props.tickGap ?? 0} tickMargin={0} tick={{
                fontSize: props.ticksSize ?? 14,
                fill: parentTheme.isDark ? '#cccccc' : '#666666',
            }} tickFormatter={time => {
                const datetime = dayAPI(time * 1000);
                const stringFormat = datetime.minute() > 0 ? 'HH:mm' : 'HH';
                return datetime.format(stringFormat);
            }}/>
        </ComposedChart>
      </ResponsiveContainer>);
    // Volume Range
    const volume = Math.max(...(chartData?.map(datum => datum.volume) || []));
    const volumeRange = NumericRange.create(0, volume * 3);
    // Price Range
    const precision = instrument.symbol.value.includes('T')
        ? 0
        : prevClose >= 50
            ? 1
            : prevClose >= 500
                ? 0
                : 2;
    const lowestPrice = Math.min(...(chartData?.map(datum => datum.close) || []));
    const highestPrice = Math.max(...(chartData?.map(datum => datum.close) || []));
    const paddedPrice = Math.max(Math.abs(lowestPrice), Math.abs(highestPrice)) * (1 + (props.pricePadding ?? 0.1));
    const priceRange = NumericRange.create(-paddedPrice, paddedPrice);
    //價格座標文字位置對齊
    const midValue = 0;
    const defaultTicks = Math.abs(highestPrice) >= Math.abs(lowestPrice)
        ? Math.abs(highestPrice) * 1
        : Math.abs(lowestPrice) * 1;
    const priceTicks = [-defaultTicks, -defaultTicks / 2, midValue, defaultTicks / 2, defaultTicks];
    const dataMax = Math.max(...chartData.map(i => i.close));
    const dataMin = Math.min(...chartData.map(i => i.close));
    /** 圖表 Area 上色 */
    const priceChangOffset = () => {
        if (dataMax <= 0)
            return 0;
        if (dataMin >= 0)
            return 1;
        return dataMax / (dataMax - dataMin);
    };
    //#region TooltipContent
    const CustomizedTooltip = ({ payload }) => {
        if (!payload || (payload && payload.length < 1))
            return null;
        const datum = payload[0].payload;
        const change = prevClose && datum.originalClose - prevClose;
        const changePct = prevClose && change && (change / prevClose) * 100;
        const arrowSymbol = change === 0 ? '' : change > 0 ? '▲' : '▼';
        const changeColor = change === 0
            ? parentTheme.isDark
                ? '#ffff22'
                : '#555555'
            : change > 0
                ? '#ee2222'
                : '#11cc11';
        const ItemText = styled.div `
      margin-left: 8px;
      color: ${props_ => props_.color};
      ${fontWeight600}
    `;
        return (<div css={css `
          padding: 0px 5px 0px 5px;
          background-color: ${parentTheme.isDark ? '#222222' : '#eeeeee'};
          color: ${parentTheme.isDark ? '#eeeeee' : '#222222'};
          opacity: 0.8;
          border: 1px solid #b4b4b4;
          border-radius: 5px;
          font-size: 14px;
          line-height: 20px;
        `}>
        <div css={flex.h.default}>
          時間
          <ItemText>{datum.datetime}</ItemText>
        </div>
        <div css={flex.h.default}>
          價格<ItemText>{datum.originalClose}</ItemText>
        </div>
        <div css={flex.h.default}>
          漲跌
          <ItemText color={changeColor}>{arrowSymbol + change?.toFixed(2)}</ItemText>
        </div>
        <div css={flex.h.default}>
          漲幅
          <ItemText color={changeColor}>{arrowSymbol + changePct?.toFixed(2) + '%'}</ItemText>
        </div>
        <div css={flex.h.default}>
          成交量
          <ItemText color={parentTheme.isDark ? '#ffff00' : '#ff9900'}>{datum.volume}</ItemText>
        </div>
      </div>);
    };
    //#endregion
    return (<ResponsiveContainer width='100%' height='100%'>
      <ComposedChart data={chartData} margin={chartMargin}>
        <YAxis yAxisId={1} dataKey='close' hide={props.hidePriceLabel ?? false} tick={{
            fontSize: props.priceTicksSize ?? 14,
            fill: parentTheme.isDark ? '#CCCCCC' : '#666666',
        }} tickMargin={0} tickFormatter={price => (price + prevClose).toFixed(precision)} domain={priceRange.toTuple()} ticks={priceTicks}/>
        <YAxis yAxisId={2} dataKey='volume' hide={true} domain={volumeRange.toTuple()}/>

        <XAxis dataKey='unixtime' type='number' height={props.ticksHeight} hide={props.hideTimeLabel ?? false} domain={timeRange} ticks={timeTicks} interval={props.tickGap ?? 0} tickMargin={0} tick={{
            fontSize: props.ticksSize ?? 14,
            fill: parentTheme.isDark ? '#cccccc' : '#666666',
        }} tickFormatter={time => {
            const datetime = dayAPI(time * 1000);
            const stringFormat = datetime.minute() ? 'HH:mm' : 'HH';
            return datetime.format(stringFormat);
        }}/>

        <CartesianGrid strokeDasharray='1' stroke={strokeColor} fill={fillColor} verticalPoints={[]} horizontalPoints={[0]}/>
        <defs>
          <linearGradient id={splitColorId} x1='0' y1='0' x2='0' y2='1'>
            <stop offset={priceChangOffset() / 1.75} stopColor={'#FF2222'} stopOpacity={0.8}/>
            <stop offset={priceChangOffset()} stopColor={parentTheme.isDark ? '#551111' : '#FF2222'} stopOpacity={0.8}/>
            <stop offset={priceChangOffset()} stopColor={parentTheme.isDark ? '#115511' : '#00CC00'} stopOpacity={0.8}/>
            <stop offset={priceChangOffset() / 0.25} stopColor={parentTheme.isDark ? '#11FF11' : '#009900'} stopOpacity={0.8}/>
          </linearGradient>
          <linearGradient id={strokeColorId} x1='0' y1='0' x2='0' y2='1'>
            <stop offset={priceChangOffset()} stopColor={'#ff2222'} stopOpacity={0.8}/>
            <stop offset={priceChangOffset()} stopColor={parentTheme.isDark ? '#11FF11' : '#00AA00'} stopOpacity={0.8}/>
          </linearGradient>
        </defs>
        <Area yAxisId={1} type='linear' dataKey='close' dot={false} stroke={strokeId} fill={fillId} fillOpacity={props.transparency ?? 0.4} isAnimationActive={false}/>
        <Bar yAxisId={2} dataKey='volume' barSize={20} fill={parentTheme.isDark ? '#3F51B5' : '#2763D6'} isAnimationActive={false}/>
        <ReferenceLine yAxisId={1} isFront={true} y={0} stroke={'#2196F3'} strokeWidth={0.5}/>
        {!props.tooltipVisible && <Tooltip content={<CustomizedTooltip />}/>}
      </ComposedChart>
    </ResponsiveContainer>);
});
export default IntradayTrendChart;
